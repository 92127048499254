import { Facebook } from "../../icons/Facebook";
import { Instagram } from "../../icons/Instagram";

export const MobileFooter = () => (
  <div className="sm:hidden px-2 py-5 pb-2 w-full flex items-center justify-center flex-col">
    <img
      src="/cut-premiere-white.png"
      alt="white-logo"
      width="350"
      height="50"
    />

    <div className="gap-x-4 mt-5 flex items-center justify-center">
      <a target="__blank" href="https://www.facebook.com/premeireparkcity/">
        <Facebook />
      </a>

      <a target="__blank" href="https://www.instagram.com/premiereparkcity/">
        <Instagram />
      </a>
    </div>

    <p className="mt-5 font-medium text-sm text-center">
      © Copyright 2023. All Rights Reserved.
    </p>

    <div className="mt-1 w-full text-center font-main-font">
      <a
        rel="noopener noreferrer"
        target="_blank"
        className="text-sm"
        href="https://www.byyair.com"
      >
        byyair.com
      </a>
    </div>
  </div>
);
