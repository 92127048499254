import { useEffect } from "react";

export const useIntersectionObserver = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-fadeIn");
        }
      });
    }, {});

    if (window.document) {
      const hiddenElements = document.querySelectorAll(".hidden-element");
      hiddenElements.forEach((element) => {
        observer.observe(element);
      });
    }
  }, []);
};
