import { MobileFooter } from "./MobileFooter";
import { DesktopFooter } from "./DesktopFooter";

export const Footer = () => {
  return (
    <footer className="text-white flex items-center justify-between w-full mt-5 px-5 sm:px-40">
      <MobileFooter />
      <DesktopFooter />
    </footer>
  );
};
