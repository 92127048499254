import { useState } from "react";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";
import useScrollToTop from "../../hooks/useScrollToTop";

export const Header = () => {
  const [opened, setOpened] = useState(false);

  useScrollToTop();

  return (
    <>
      <header className="text-white w-full h-auto sm:px-20">
        <DesktopHeader />
        <MobileHeader opened={opened} setOpened={setOpened} />
      </header>
    </>
  );
};
