import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

export const ContactUsPage = () => {
  useIntersectionObserver();
  return (
    <>
      <div className="w-full flex flex-col items-start justify-center">
        <section className="w-full mt-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
          <h3 className="hidden-element sm:text-[80px] leading-none text-4xl font-main-font">
            Contact Us
          </h3>
        </section>

        <section className="mt-10 w-full flex-col flex items-center justify-center px-5 sm:px-[150px]">
          <p className="text-xl underline py-2">Contact Us:</p>
          <span>📍 268 Main Street, Park City, Utah</span>
          <span>🕒 Wednesday – Sunday 5pm - Late</span>
        </section>

        <section className="w-full sm:flex-row flex-col flex items-center justify-center px-5 sm:px-[150px]">
          <iframe
            id="JotFormIFrame-240205690713046"
            title="Bar Contact"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/240205690713046"
            width={500}
            height={300}
          ></iframe>
        </section>
      </div>
    </>
  );
};
