import "./App.css";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import { HomePage } from "./routes/HomePage";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { ContactUsPage } from "./routes/Contact";
import { AboutUsPage } from "./routes/AboutUs";
import { MenuPage } from "./routes/Menu";
import { EventsPage } from "./routes/Events";
import { GalleryPage } from "./routes/Gallery";
import { Reservations } from "./routes/Reservations";
import { PressPage } from "./routes/Press";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/reservations" element={<Reservations />} />
        <Route path="/press" element={<PressPage />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
