import { Dispatch, SetStateAction, useCallback, useEffect } from "react";

import classnames from "classnames";
import { HamburgerMenuIcon } from "../../icons/HamburgerMenuIcon";

interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

export const MobileHeader = ({ opened, setOpened }: Props) => {
  const LinkButton = useCallback(
    ({ href, text }: { href: string; text: string }) => {
      return (
        <a href={href} className="text-white text-2xl font-semibold">
          {text}
        </a>
      );
    },
    []
  );

  useEffect(() => {
    setOpened(false);
  }, [setOpened]);

  useEffect(() => {
    if (opened) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }

    return () => document.body.classList.remove("overflow-y-hidden");
  }, [opened]);

  return (
    <>
      <div className="sm:hidden flex w-full items-center relative justify-center h-[80px]">
        <a href="/">
          <img
            src="/cut-premiere-white.png"
            alt="white-logo"
            width="130"
            height="50"
            className="pt-3"
          />
        </a>
        <div className="text-white absolute left-2 top-[22px]">
          <button onClick={() => setOpened(true)}>
            <HamburgerMenuIcon />
          </button>
        </div>
      </div>
      <div
        className={classnames(
          "transition-all duration-300 -z-10 opacity-0 absolute left-0 top-0 right-0 bottom-0 bg-black flex items-center justify-center",
          {
            "opacity-100 z-50": opened,
          }
        )}
      >
        <div className="font-main-font relative h-full w-full flex items-center justify-center flex-col gap-y-4">
          <button
            onClick={() => setOpened(false)}
            className="absolute left-3 top-[32px]"
          >
            Close
          </button>

          <LinkButton href="/" text="Home" />
          <LinkButton href="/menu" text="Menu" />
          <LinkButton href="/events" text="Events" />
          <LinkButton href="/gallery" text="Gallery" />
          <LinkButton href="/about-us" text="About Us" />
          <LinkButton href="/reservations" text="Reservations" />
          <LinkButton href="/press" text="Press" />
          <LinkButton href="/contact-us" text="Contact Us" />
          <a
            className="text-white text-2xl font-semibold"
            href="https://premiere-coworking.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            WORKSPACE
          </a>
        </div>
      </div>
    </>
  );
};
