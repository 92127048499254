import { Link } from "react-router-dom";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

export const EventsPage = () => {
  useIntersectionObserver();

  return (
    <>
      <div className="w-full flex flex-col items-start justify-center">
        <section className="w-full mt-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
          <h3 className="hidden-element sm:text-[80px] leading-none text-4xl font-main-font">
            Events
          </h3>
        </section>

        <section className="sm:w-2/3 w-full flex-col mt-10 gap-y-4 flex items-start justify-center px-10 sm:px-32">
          <p className="hidden-element text-2xl leading-none font-main-font">
            Premiere Lounge is your canvas for celebrations of all kinds. Our
            venue is designed to host events ranging from intimate gatherings to
            grand soirées. With a capacity to accommodate both small and large
            groups, we are the perfect choice for community functions,
            birthdays, weddings, corporate parties, family gatherings, and more.
            The versatility of our space allows for seamless customization to
            suit the unique requirements of each event.
          </p>

          <Link
            to="/contact-us"
            className="font-main-font px-2 py-1 border border-primary-border text-xl"
          >
            Contact Us
          </Link>
        </section>

        <section className="mt-20 w-full sm:flex-row flex-col flex items-center justify-center px-5 sm:px-[150px]"></section>
      </div>
    </>
  );
};
