import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

const images = [
  {
    src: "./bar-bg.png",
    original: "./bar-bg.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-1.png",
    original: "./bar-bg-box-1.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-2.png",
    original: "./bar-bg-box-2.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-4.png",
    original: "./bar-bg-box-4.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-4.png",
    original: "./bar-bg-box-4.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-3.png",
    original: "./bar-bg-box-3.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg.png",
    original: "./bar-bg.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-1.png",
    original: "./bar-bg-box-1.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-3.png",
    original: "./bar-bg-box-3.png",
    width: 1250,
    height: 700,
  },
  {
    src: "./bar-bg-box-2.png",
    original: "./bar-bg-box-2.png",
    width: 1250,
    height: 700,
  },
];

export const GalleryPage = () => {
  useIntersectionObserver();

  const [index, setIndex] = useState(-1);

  const slides = images.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
  }));

  const handleClick = (index: number, item: any) => setIndex(index);

  return (
    <>
      <div className="w-full flex flex-col items-start justify-center">
        <section className="w-full mt-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
          <h3 className="hidden-element sm:text-[80px] leading-none text-4xl font-main-font">
            Gallery
          </h3>
        </section>

        {/* Image Gallery Avi Created */}
        {/* <iframe
          id="JotFormIFrame-240213724099050"
          className="mt-10 bg-transparent border-none w-full sm:max-h-[1200px] max-h-[600px]"
          width="100%"
          height={1200}
          title="Gallery"
          onLoad={() => {
            window.parent.scrollTo(0, 0);
          }}
          style={{ border: "none", background: "none" }}
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/240213724099050"
          frameBorder={0}
        /> */}
      </div>

      <div className="w-full px-10 sm:px-32 mt-10">
        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
        <Lightbox
          slides={slides}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
      </div>
    </>
  );
};
