export const HamburgerMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="40"
    viewBox="0 -960 960 960"
    width="40"
    fill="white"
  >
    <path d="M120-240v-66.666h720V-240H120Zm0-206.667v-66.666h720v66.666H120Zm0-206.667V-720h720v66.666H120Z" />
  </svg>
);
