import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

export const PressPage = () => {
  useIntersectionObserver();

  return (
    <>
      <div className="w-full flex flex-col items-start justify-start min-h-screen">
        <section className="w-full mt-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
          <h3 className="hidden-element sm:text-[80px] leading-none text-4xl font-main-font">
            Press
          </h3>
        </section>

        <section className="w-full gap-6 flex-col sm:flex-row mt-10 gap-y-4 flex items-start justify-start flex-wrap px-10 sm:px-32">
          {/* Town Lift */}
          <div className="sm:w-[350px] h-auto w-full hidden-element">
            <div className="w-full overflow-hidden bg-white h-[180px] p-4 flex items-center justify-center">
              <img src="/townlift.png" alt="townlift" />
            </div>
            <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
              <div className="text-center border-2 border-primary-border h-full w-full p-4 h-ull gap-y-2 flex flex-col items-center justify-center">
                <p className="text-xl font-main-font">TownLift</p>
                <p className="text-sm">
                  "New cocktail lounge and venue, Premiere, opens in Park City"
                </p>
                <a
                  target="_blank"
                  className="text-sm font-bold"
                  rel="noopener noreferrer"
                  href="https://townlift.com/2024/04/new-cocktail-lounge-and-venue-premiere-opens-in-park-city/"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          {/* Fox 13 */}
          <div className="sm:w-[350px] h-auto w-full hidden-element">
            <div className="w-full overflow-hidden bg-white h-[180px] p-4 flex items-center justify-center">
              <img src="/fox13.png" alt="townlift" />
            </div>
            <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
              <div className="text-center border-2 border-primary-border h-full w-full p-4 h-ull gap-y-2 flex flex-col items-center justify-center">
                <p className="text-xl font-main-font">FOX 13</p>
                <p className="text-sm">
                  "Utah has a swanky new underground cocktail lounge"
                </p>
                <a
                  target="_blank"
                  className="text-sm font-bold"
                  rel="noopener noreferrer"
                  href="https://www.fox13now.com/the-place/utah-has-a-swanky-new-underground-cocktail-lounge"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          {/* Salt Lake Weekly */}
          <div className="sm:w-[350px] h-auto w-full hidden-element">
            <div className="bg-gray-400 overflow-hidden w-full h-[180px] p-4 flex items-center justify-center">
              <img src="/saltlakeweekly.png" alt="saltlakeweekly" />
            </div>
            <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
              <div className="text-center sm:min-h-[140px] border-2 border-primary-border h-full w-full p-4 gap-y-2 flex flex-col items-center justify-center">
                <p className="text-xl font-main-font">Salt Lake City Weekly</p>
                <p className="text-sm">"Premiere Opens in Park City"</p>
                <a
                  target="_blank"
                  className="text-sm font-bold"
                  rel="noopener noreferrer"
                  href="https://issuu.com/cityweeklynewspaper/docs/cw_archive_240509"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
