import { Link } from "react-router-dom";
import { Facebook } from "../../icons/Facebook";
import { Instagram } from "../../icons/Instagram";

export const DesktopFooter = () => (
  <div className="hidden sm:flex flex-col pt-5  items-center justify-between w-full">
    <div className="w-full flex items-center justify-between">
      <div className="flex items-center justify-center flex-col">
        <div className="w-[140px] h-[60px]">
          <img
            src="/cut-premiere-white.png"
            alt="white-logo"
            width="140"
            height="60"
          />
        </div>

        <div className="flex items-center justify-center gap-x-6">
          <a target="__blank" href="https://www.facebook.com/premeireparkcity/">
            <Facebook />
          </a>

          <a
            target="__blank"
            href="https://www.instagram.com/premiereparkcity/"
          >
            <Instagram />
          </a>
        </div>
      </div>

      <div>
        <div className="flex items-center text-lg justify-between gap-x-4 font-main-font">
          <Link to="/menu">Menu</Link>
          <Link to="/events">Events</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/reservations">Reservations</Link>
          <Link to="/press">Press</Link>
          <Link to="/contact-us">Contact Us</Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://premiere-coworking.vercel.app/"
          >
            WORKSPACE
          </Link>
        </div>
      </div>
    </div>

    <div className="border-primary-border border-b-2 w-full h-1 my-4" />
    <p className="text-sm font-medium">
      © Copyright 2023. All Rights Reserved.
    </p>
    <a
      rel="noopener noreferrer"
      target="_blank"
      className="text-sm cursor-pointer font-main-font"
      href="https://www.byyair.com"
    >
      byyair.com
    </a>
  </div>
);
