import { Link } from "react-router-dom";

export const DesktopHeader = () => {
  return (
    <div className="hidden sm:flex items-center justify-between w-full h-[80px]">
      <Link to="/">
        <img
          src="/cut-premiere-white.png"
          alt="white-logo"
          width="140"
          height="50"
        />
      </Link>
      <div className="flex items-center text-lg justify-between gap-x-4 font-main-font">
        <Link to="/menu">Menu</Link>
        <Link to="/events">Events</Link>
        <Link to="/gallery">Gallery</Link>
        <Link to="/about-us">About Us</Link>
        <Link to="/reservations">Reservations</Link>
        <Link to="/press">Press</Link>
        <Link to="/contact-us">Contact Us</Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://premiere-coworking.vercel.app/"
        >
          WORKSPACE
        </Link>
      </div>
    </div>
  );
};
