import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

export const AboutUsPage = () => {
  useIntersectionObserver();

  return (
    <>
      <div className="w-full flex flex-col items-start justify-center">
        <section className="w-full mt-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
          <h3 className="hidden-element sm:text-[80px] leading-none text-4xl font-main-font">
            About Us
          </h3>
        </section>

        <section className="sm:w-2/3 w-full flex-col mt-10 gap-y-4 flex items-start justify-center px-10 sm:px-32">
          <p className="hidden-element text-2xl leading-none font-main-font">
            Welcome to Premiere Lounge Where Elegance Meets Entertainment in the
            Heart of Park City, Utah!
          </p>
          <p className="hidden-element leading-none text-2xl font-main-font">
            Premiere Lounge is not just a venue; it's an experience crafted for
            those who seek a perfect blend of sophistication, delectable
            cuisine, and unforgettable moments. Nestled in the scenic beauty of
            Park City, our brand-new lounge spans an impressive 5000 square
            feet, providing an intimate yet spacious setting for a diverse array
            of events
          </p>
        </section>

        <section className="w-full mt-20 flex items-center justify-center">
          <video
            src="https://player.vimeo.com/progressive_redirect/playback/904778495/rendition/1080p/file.mp4?loc=external&signature=978530dc0e971ce74fe7ecf668b7a35ff864c9a33b1a63d807c8cc709bf6abf8"
            className="sm:w-4/6 w-full h-auto"
            title="Bar Video"
            controls
            loop
          />
        </section>

        <section className="mt-20 w-full sm:flex-row flex-col flex items-center justify-center px-5 sm:px-[150px]"></section>
      </div>
    </>
  );
};
