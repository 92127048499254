import { Document, Page, pdfjs } from "react-pdf";

import { useIntersectionObserver } from "../hooks/useIntersectionObserver";
import { useEffect } from "react";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export const MenuPage = () => {
  useIntersectionObserver();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  return (
    <div className="w-full flex-col flex items-start justify-start">
      <section className="w-full my-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
        <h3 className="hidden-element sm:text-[80px] leading-none text-4xl z-10 font-main-font">
          Our Menus
        </h3>

        <div className="relative mt-10 sm:mt-20 w-[300px] h-[300px] self-center sm:self-center">
          <div className="hidden-element delay-image-transition absolute top-[-50px] sm:right-[-50px] right-[-20px] h-[250px] sm:h-[350px] w-[250px] sm:w-[350px] bg-center bg-cover bg-[url('/public/cocktail-3.png')]" />
        </div>
      </section>

      <Document
        className="w-full bg-black p-0 m-0 sm:mt-0 mt-[-250px] hidden-element delay-image-transition flex sm:flex-row flex-col sm:scale-100 scale-75 sm:flex-wrap gap-x-10 items-center justify-center"
        file="./menus.pdf"
      >
        <Page className="bg-black md:scale-100 scale-75" pageNumber={2} />
        <Page className="bg-black md:scale-100 scale-75" pageNumber={1} />
      </Document>
    </div>
  );
};
