import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

export const Reservations = () => {
  useIntersectionObserver();

  return (
    <div className="w-full flex flex-col items-start justify-center">
      <section className="w-full mt-10 flex lg:flex-row flex-col items-start sm:justify-between justify-center px-10 sm:px-32">
        <h3 className="hidden-element sm:text-[80px] leading-none text-4xl font-main-font">
          Reservations
        </h3>
      </section>

      <div className="w-full px-10 sm:px-32 mt-10 flex items-center justify-center min-h-[500px]">
        <iframe
          data-test="reservation-widget-iframe"
          src="https://www.opentable.com/widget/reservation/mural?rid=1359358&amp;type=standard&amp;color=1&amp;dark=false&amp;iframe=true&amp;domain=com&amp;lang=en-US&amp;newtab=true&amp;ot_source=Restaurant website&amp;cfe=true&amp;uuid=fd7baaf3-00d4-4366-9790-f2a6949783aa2&amp;theme=standard"
          width="224"
          height="301"
          name="opentable-make-reservation-widget"
          title="OpenTable make reservation widget"
        ></iframe>
      </div>
    </div>
  );
};
