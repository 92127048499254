import { Link } from "react-router-dom";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

export const HomePage = () => {
  useIntersectionObserver();

  return (
    <>
      <main className="flex flex-col items-center justify-center">
        <section className="relative w-full flex items-center justify-center sm:h-[450px] h-[250px]">
          <div className="hidden-element flex items-center justify-center flex-col z-10">
            <p className="text-sm mt-0">Since 2023</p>
            <h4 className="text-6xl mt-0 font-main-font">Premiere</h4>
            <p className="font-main-font sm:text-xl px-10 text-center text-md">
              Your Go-To Destination for Exceptional Evenings
            </p>
          </div>

          <div className="hidden-element absolute top-0 sm:h-[450px] h-[250px]  right-0 left-0 bottom-0 bg-center bg-no-repeat bg-cover bg-[url('/public/bar-bg.png')] " />
          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-50"></div>
          <div className="absolute !opacity-100 -bottom-1 left-0 right-0 h-[200px] w-full bg-gradient-to-b from-transparent to-black"></div>
        </section>

        <section className="hidden-element sm:flex-row flex-col flex items-center justify-center w-full sm:h-[500px] h-[750px] mt-[150px]">
          <div className="flex-1 items-center justify-center flex h-full w-full">
            <div className="w-full h-full sm:p-0 p-5">
              <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
                <div className="text-center border-2 border-primary-border h-full w-full p-4 h-ull gap-y-4 flex flex-col items-center justify-center">
                  <p className="font-main-font sm:text-4xl text-3xl sm:px-4">
                    Unmatched Culinary and Musical Experiences in Park City
                  </p>
                  <p className="px-4">
                    Situated in the bustling heart of Park City, Premiere Park
                    City stands as more than just a venue – it's an immersive
                    journey into a world of sophisticated flavors, captivating
                    live music, and versatile event spaces, perfect for locals
                    and tourists alike.
                  </p>
                  <div className="sm:w-[450px] w-5/6 flex items-center justify-center">
                    <Link
                      to="/about-us"
                      className="font-main-font px-2 py-1 border border-primary-border text-xl"
                    >
                      Discover Our Venue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            className="flex-1 hidden-element delay-image-transition w-full h-full"
            src="/bar-bg-box-1.png"
            alt="Bar Image"
          />
        </section>

        <section className="hidden-element flex-col-reverse sm:flex-row flex items-center justify-center w-full sm:h-[500px] h-[750px]">
          <img
            src="/bar-bg-box-2.png"
            alt="Bar Image - Whiskey Bottles"
            className="flex-1 hidden-element delay-image-transition w-full h-full"
          />

          <div className="flex-1 items-center justify-center flex h-full w-full">
            <div className="w-full h-full sm:p-0 p-5">
              <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
                <div className="text-center border-2 border-primary-border h-full w-full flex gap-y-8 flex-col items-center justify-center">
                  <div className="text-center border-2 border-primary-border p-4 h-full w-full h-ull gap-y-4 flex flex-col items-center justify-center">
                    <p className="font-main-font sm:text-4xl text-3xl sm:px-4">
                      Indulge in Artisan Craft Cocktails and Exquisite Dining
                      Locally-Sourced Ingredients Meet Innovative Mixology
                    </p>
                    <p className="px-4">
                      Step into our realm of extraordinary taste, where each
                      craft cocktail is a fusion of creativity and the finest
                      local ingredients. Our chef's menu, featuring the best of
                      Utah's produce, perfectly complements our innovative
                      drinks selection.
                    </p>
                    <div className="sm:w-[450px] w-5/6 flex items-center justify-center">
                      <Link
                        to="/menu"
                        className="font-main-font px-2 py-1 border border-primary-border text-xl"
                      >
                        Explore Our Menu
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hidden-element flex-col sm:flex-row flex items-center justify-center w-full sm:h-[500px] h-[750px]">
          <div className="flex-1 items-center justify-center flex h-full w-full">
            <div className="w-full h-full sm:p-0 p-5">
              <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
                <div className="text-center border-2 border-primary-border h-full w-full h-ull gap-y-4 flex flex-col items-center justify-center">
                  <div className="text-center h-full w-full h-ull gap-y-4 p-4 flex flex-col items-center justify-center">
                    <p className="font-main-font sm:text-4xl text-3xl sm:px-4">
                      The Heartbeat of Park City's Nightlife Live Music and
                      Entertainment Every Night
                    </p>
                    <p className="px-4">
                      Dive into the vibrant nightlife of Park City with our
                      eclectic live music lineup. Premiere Park City is the
                      place to be for unforgettable evenings filled with music,
                      energy, and dance.
                    </p>

                    <div className="sm:w-[450px] w-5/6 flex items-center justify-center">
                      <Link
                        to="/menu"
                        className="font-main-font px-2 py-1 border border-primary-border text-xl"
                      >
                        See Our Upcoming Events
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            className="flex-1 hidden-element delay-image-transition w-full h-full"
            src="/bar-bg-box-3.png"
            alt="Bar Image - Live Music"
          />
        </section>

        <section className="hidden-element flex-col-reverse sm:flex-row flex items-center justify-center w-full sm:h-[500px] h-[750px]">
          <img
            className="flex-1 hidden-element delay-image-transition w-full h-full"
            src="/bar-bg-box-4.png"
            alt="Bar Image - Sitting area"
          />

          <div className="flex-1 items-center justify-center flex h-full w-full">
            <div className="w-full h-full sm:p-0 p-5">
              <div className="w-full h-full flex items-center justify-center p-1 border border-primary-border">
                <div className="text-center border-2 border-primary-border h-full w-full h-ull gap-y-4 flex flex-col items-center justify-center">
                  <div className="text-center h-full w-full h-ull gap-y-4 p-4 flex flex-col items-center justify-center">
                    <p className="font-main-font sm:text-4xl text-3xl sm:px-4">
                      Your Perfect Setting for Every Event Elegant Spaces for
                      Weddings, Corporate Functions, and Celebrations
                    </p>
                    <p className="px-4">
                      Whether planning an intimate birthday gathering or a grand
                      corporate event, our versatile spaces provide the ideal
                      backdrop. Let us transform your vision into a memorable
                      event.
                    </p>

                    <div className="sm:w-[450px] w-5/6 flex items-center justify-center">
                      <Link
                        to="/menu"
                        className="font-main-font px-2 py-1 border border-primary-border text-xl"
                      >
                        Plan Your Event with Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hidden-element delay-image-transition relative w-full flex sm:flex-row flex-col items-center mt-28 sm:mt-0 justify-center h-[350px]">
          <div className="flex-1 text-center">
            <h4 className="font-main-font text-4xl">
              268 Main Street, Park City, Utah
            </h4>
            <a
              className="text-center underline"
              rel="noreferrer"
              target="_blank"
              href="https://maps.app.goo.gl/Udik9kcoAh3mjZ5B7"
            >
              Get Directions
            </a>
          </div>

          <div className="flex-1 text-center">
            <h4 className="font-main-font text-4xl">Hours</h4>
            <p className="underline text-center">
              Wednesday – Sunday 5pm - Late
            </p>
          </div>
        </section>
      </main>
    </>
  );
};
